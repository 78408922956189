import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import GlobalContext from "../../context/GlobalContext";
import { getRemainingVacation } from "../../services/Vacation";
import { useLocation } from "react-router-dom";
import axios from "axios";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useParams } from "react-router-dom";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SickIcon from "@mui/icons-material/Sick";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import {
  addHolidayEntry,
  addSickdayEntry,
  addOvertimeUse,
  deleteEntry,
  fetchEntries,
  saveNewEntry,
  updateEntry,
  addBusinessTripEntry,
  deleteAllEntriesByDate,
  deleteOverTimeEntry,
  addRemoteEntry,
  removeRemoteEntry,
} from "../../store/redux/slices/entriesSlice";
import "../Calendar/Calendar.css";
import utc from "dayjs/plugin/utc";
import { TextField } from "@mui/material";
import WorkingTimeForm from "./WorkingTimeForm";
import NotesForm from "./NotesForm";
import { BroadcastOnPersonalOutlined } from "@mui/icons-material";

// Helper function
// import { getEntries } from "../Admin/User";

dayjs.extend(utc);

export default function EventModal({ onNoteSubmit }) {
  let userID = localStorage.getItem("userId");

  const location = useLocation();

  const today = new Date();
  const futureDate = new Date(today.getTime() + 8 * 60 * 60 * 1000);

  const {
    setShowEventModal,
    daySelected,
    dispatchCalEvent,
    selectedEvent,
    selectedUser,
    setRefresh,
    refresh,
  } = useContext(GlobalContext);

  const [startTime, setStartTime] = useState(
    dayjs(daySelected).utc().set("hours", 7)
  );
  const [endTime, setEndTime] = useState(
    dayjs(daySelected).utc().set("hours", 15)
  );

  const [diffStatus, setdiffStatus] = useState(null);
  const [overtimeAllowed, setOvertimeAllowed] = useState(false);

  const [displayStartTime, setDisplayStartTime] = useState(null);
  const [displayEndTime, setDisplayEndTime] = useState(null);

  const [rdc, setRdc] = useState(null);

  const [rdv, setRdv] = useState(null);
  const [dop, setDop] = useState(null);
  const [drp, setDrp] = useState(null);
  const [ocd, setOcd] = useState(null);
  const [bp8, setBp8] = useState(null);

  const [nadure, setNadure] = useState(
    selectedEvent ? selectedEvent.nadure : 0
  );

  const [koriscenje, setKoriscenje] = useState(0);

  const [opomba, setOpomba] = useState(
    selectedEvent ? selectedEvent.notes : null
  );

  const [koriscenjeToggle, setKoriscenjeToggle] = useState(false);
  const [sickdayToggle, setSickdayToggle] = useState(false);

  const [vacationDays, setVacationDays] = useState(0);
  const [data, setData] = useState(null);

  const dispatch = useDispatch();
  const params = useParams();

  const user = useSelector((state) => state.msalInstance.user);
  userID = user.idTokenClaims.sub;

  // let username = location.state ? location.state.name : user.username;
  let username = selectedUser ? selectedUser : user.username;

  let selectedEventData = selectedEvent ? { ...selectedEvent } : {};

  const overtimeUseInputRef = useRef();
  const sickdayInputRef = useRef();

  const [overtimeUseInputError, setOvertimeUseInputError] = useState(false);
  const [sickdayInputError, setSickdayInputError] = useState(false);
  const [hiddenNotesForm, setHiddenNotesForm] = useState(false);

  const { monthIndex, setMonthIndex } = useContext(GlobalContext);

  const [approvalStatus, setApprovalStatus] = useState(true);

  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteEntireModalOpen, setDeleteEntireModalOpen] = useState(false);
  const [overtimeDeleteModalOpen, setOvertimeDeleteModalOpen] = useState(false);
  const [hasPaId, setHasPaId] = useState(false);

  const [modalHidden, setModalHidden] = useState(false);

  const [mongoUser, setMongoUser] = useState(null);

  useEffect(() => {
    if (location.pathname.includes("/user")) {
      // username = location.state.name;
      username = selectedUser;
    } else {
      username = user.username;
    }

    const getMongoUser = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/${username}`
      );
      setMongoUser(response.data);
    };
    getMongoUser();

    const VacationDays = async () => {
      if (location.pathname.includes("/user")) {
        // const vacationDays = await getRemainingVacation(location.state.name);
        const vacationDays = await getRemainingVacation(selectedUser);
        setVacationDays(vacationDays);
      } else {
        const vacationDays = await getRemainingVacation(username);
        setVacationDays(vacationDays);
      }
    };
    VacationDays();
    GetEntry();

    checkApproveStatus();
  }, [username, selectedEvent]);

  useEffect(() => {
    checkRole();

    if (selectedEvent) {
      checkDiffTime();
    }
  }, [startTime, endTime, overtimeAllowed]);

  const GetEntry = async () => {
    if (selectedEvent != null) {
      const response = axios
        .post(process.env.REACT_APP_API_URL + `/entries/getTodayEntries`, {
          username: [username, "system"],
          date: selectedEvent.date,
        })
        .then((response) => {
          let tempRdc = 0;
          let tempRdv = 0;
          let tempNadure = 0;
          let tempKoriscenje = 0;
          let drp = 0;
          let tempStart = null;
          let tempEnd = null;

          response.data.forEach((element) => {
            if (element.rdc != undefined) {
              tempRdc += element.rdc;
            }

            if (element.rdv != undefined) {
              tempRdv += element.rdv;
            }

            if (element.nadure != undefined) {
              tempNadure += element.nadure;
            }

            if (element.startTime != undefined) {
              tempStart = element.startTime;
            }

            if (element.endTime != undefined) {
              tempEnd = element.endTime;
            }

            if (element.koriscenje != undefined) {
              tempKoriscenje = element.koriscenje;
            }
          });

          setDisplayStartTime(tempStart);
          setDisplayEndTime(tempEnd);
          setRdc(tempRdc);
          setRdv(tempRdv);
          setNadure(tempNadure);
          setKoriscenje(tempKoriscenje);

          setData(response.data);
        });
    }
  };

  const checkRole = () => {
    if (user.idTokenClaims.roles != null) {
      if (
        user.idTokenClaims.roles.includes("Admin") ||
        user.idTokenClaims.roles.includes("Normal") ||
        user.idTokenClaims.roles.includes("Normal6")
      ) {
        setOvertimeAllowed(false);
      } else {
        setOvertimeAllowed(true);
      }
    }
  };

  const handleDeleteEntry = async () => {
    const selectedDate = daySelected.valueOf();

    const entryToDelete = {
      date: selectedDate,
      // username: location.state.name,
      username: selectedUser,
      entry_id: selectedEventData.entry_id,
      day: selectedDate.valueOf(),
      hasPaId: hasPaId,
    };
    dispatch(deleteEntry({ entryToDelete }));

    // dispatch(deleteEntry({ selectedEvent }));
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
      username: user.username,
      date: today,
      action: "deleted entry",
      day: selectedDate.valueOf(),
      system: false,
    });
  };

  const handleDeleteEntireEntry = async () => {
    await handleDeleteOvertimeUse();
    const selectedDate = daySelected.valueOf();
    dispatch(
      deleteAllEntriesByDate({ username: selectedUser, date: selectedDate })
    );
    // dispatch(
    //   deleteAllEntriesByDate({
    //     username: location.state.name,
    //     date: selectedDate,
    //   })
    // );

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
      username: user.username,
      date: today,
      action: "deleted all entries for the day",
      day: selectedDate.valueOf(),
      system: false,
    });
    // fetchEntries({ username });
  };

  const handleDeleteOvertimeUse = async () => {
    const selectedDate = daySelected.valueOf();

    if (!selectedEventData.koriscenje) {
      // console.log("No overtime use entry to delete.");
      return;
    }

    const entryToDelete = {
      date: selectedDate,
      username: selectedUser,
      // username: location.state.name,
      entry_id: selectedEventData.entry_id,
      day: selectedDate.valueOf(),
      koriscenje: selectedEventData.koriscenje,
    };
    dispatch(deleteOverTimeEntry({ entryToDelete }));

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
      username: user.username,
      date: today,
      action: "deleted overtime use",
      day: selectedDate.valueOf(),
      system: false,
    });

    // setRefresh((prev) => !prev);
    setRefresh((prev) => !prev);
  };

  const addHoliday = async () => {
    const selectedDate = daySelected.valueOf();

    const day = {
      date: selectedDate,
      username: username,
      day: selectedDate.valueOf(),
      entry_id: Date.now(),
      dop: 8,
    };

    dispatch(addHolidayEntry(day));
    dispatch(fetchEntries({ username }));

    setShowEventModal(false);

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
      username: user.username,
      date: today,
      action: "added holiday",
      day: selectedDate.valueOf(),
      system: false,
    });
  };

  const addSickday = async () => {
    const selectedDate = daySelected.valueOf();
    const sickdayInput = sickdayInputRef.current.value;

    const day = {
      date: selectedDate,
      username: username,
      day: selectedDate.valueOf(),
      entry_id: Date.now(),
      bp8: sickdayInput,
    };

    dispatch(addSickdayEntry(day));
    dispatch(fetchEntries({ username }));

    setShowEventModal(false);

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
      username: user.username,
      date: today,
      action: "added sickday",
      day: selectedDate.valueOf(),
      system: false,
    });
  };

  const addBusinessTrip = async () => {
    const startDate = new Date(
      localStorage.getItem("currentYear"),
      monthIndex - 1,
      1
    );
    const endDate = new Date(
      localStorage.getItem("currentYear"),
      monthIndex + 1,
      31
    );
    const selectedDate = daySelected.valueOf();

    const day = {
      date: selectedDate,
      username: username,
      day: selectedDate.valueOf(),
      entry_id: Date.now(),
      startTime: startTime,
      endTime: endTime,
      notes: "Službeno potovanje",
    };

    dispatch(addBusinessTripEntry(day));

    setShowEventModal(false);

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
      username: user.username,
      date: today,
      action: "business trip",
      day: selectedDate.valueOf(),
      system: false,
    });

    dispatch(fetchEntries({ username, startDate, endDate }));
  };

  const addRemote = async () => {
    const startDate = new Date(
      localStorage.getItem("currentYear"),
      monthIndex - 1,
      1
    );
    const endDate = new Date(
      localStorage.getItem("currentYear"),
      monthIndex + 1,
      31
    );
    const selectedDate = daySelected.valueOf();
    // selectedDate.setUTCHours(0, 0, 0, 0);
    const day = {
      date: selectedDate,
      // username: username,
      username: selectedUser,
      startDate: startDate,
      endDate: endDate,
      remote: true,
      day: selectedDate.valueOf(),
    };

    await dispatch(addRemoteEntry(day));

    setShowEventModal(false);

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
      username: user.username,
      date: today,
      action: "added remote work",
      day: today.valueOf(),
      system: false,
    });
  };

  async function deleteRemote(e) {
    e.preventDefault();
    var selectedDate = daySelected.valueOf();
    var selectedEventId = selectedEventData.entry_id;

    const day = {
      date: selectedDate,
      username: selectedUser,
      entry_id: selectedEventId,
      day: selectedDate.valueOf(),
      remote: false,
    };

    dispatch(removeRemoteEntry(day));
    dispatch(fetchEntries({ username }));
    setShowEventModal(false);
  }

  const overtimeUse = async () => {
    const startDate = new Date(
      localStorage.getItem("currentYear"),
      monthIndex - 1,
      1
    );
    const endDate = new Date(
      localStorage.getItem("currentYear"),
      monthIndex + 1,
      31
    );
    const selectedDate = daySelected.valueOf();
    const overtimeUseInput = overtimeUseInputRef.current.value;

    if (
      overtimeUseInput !== "" &&
      overtimeUseInput > 0 &&
      overtimeUseInput <= 8
    ) {
      const day = {
        date: selectedDate,
        username: username,
        day: selectedDate.valueOf(),
        entry_id: Date.now(),
        koriscenje: overtimeUseInput,
      };

      await dispatch(addOvertimeUse(day));

      setOvertimeUseInputError(false);
      setShowEventModal(false);
    } else {
      setOvertimeUseInputError(true);
    }

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
      username: user.username,
      date: today,
      action: "added overtime use",
      day: selectedDate.valueOf(),
      system: false,
    });
    await dispatch(fetchEntries({ username, startDate, endDate }));
  };

  const checkDiffTime = () => {
    const start = selectedEvent.startTime
      ? dayjs(selectedEvent.startTime)
      : null;
    const end = selectedEvent.endTime ? dayjs(selectedEvent.endTime) : null;

    if (start != null && end != null && !selectedEvent.rdc) {
      const differenceInMinutes = end.diff(start, "minute");
      const hours = Math.floor(differenceInMinutes / 60);
      const minutes = differenceInMinutes % 60;

      if (differenceInMinutes > 60 && differenceInMinutes <= 420) {
        setdiffStatus(true);
      } else {
        setdiffStatus(false);
      }
    } else {
      if (selectedEvent.rdc + selectedEvent.rdv < 8) {
        setdiffStatus(true);
      } else {
        setdiffStatus(false);
      }
    }
  };

  const checkOvertimeUseInput = () => {
    const overtimeUseInput = overtimeUseInputRef.current.value;

    if (
      overtimeUseInput !== "" &&
      overtimeUseInput > 0 &&
      overtimeUseInput <= 8
    ) {
      setOvertimeUseInputError(false);
    } else {
      setOvertimeUseInputError(true);
    }
  };

  const checkSickdayInput = () => {
    const sickdayInput = sickdayInputRef.current.value;

    if (sickdayInput !== "" && sickdayInput > 0 && sickdayInput <= 8) {
      setSickdayInputError(false);
    } else {
      setSickdayInputError(true);
    }
  };

  const toggleSickday = () => {
    setSickdayToggle((prevState) => !prevState);
  };

  const handleKoriscenjeToggle = () => {
    setKoriscenjeToggle((prevState) => !prevState);
  };

  const handleOvertimeDeleteModalToggle = () => {
    setOvertimeDeleteModalOpen((prevState) => !prevState);
  };

  const handleOpenModal = (type) => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleOpenDeleteEntireModal = () => {
    setDeleteEntireModalOpen(true);
  };

  const handleCloseDeleteEntireModal = () => {
    setDeleteEntireModalOpen(false);
  };

  const setHiddenNotes = async (onNoteSubmit) => {
    setHiddenNotesForm(true);
    setOpomba(onNoteSubmit);
  };

  async function deleteNotes(e) {
    e.preventDefault();
    var selectedDate = daySelected.valueOf();
    var selectedEventId = selectedEventData.entry_id;

    const day = {
      date: selectedDate,
      username: username,
      entry_id: selectedEventId,
      day: selectedDate.valueOf(),
      notes: "",
    };

    dispatch(updateEntry(day));
    dispatch(fetchEntries({ username }));
    setOpomba(null);
  }

  function getMonthNumber(monthName) {
    const months = {
      januar: 1,
      februar: 2,
      marec: 3,
      april: 4,
      maj: 5,
      junij: 6,
      julij: 7,
      avgust: 8,
      september: 9,
      oktober: 10,
      november: 11,
      december: 12,
    };

    return months[monthName];
  }

  const checkApproveStatus = async () => {
    const username = user.username;
    const month = monthIndex + 1;
    const year = dayjs(
      new Date(localStorage.getItem("currentYear"), monthIndex)
    ).format("YYYY");

    const monthNumber = getMonthNumber(
      dayjs(new Date(localStorage.getItem("currentYear"), monthIndex)).format(
        "MMMM"
      )
    );

    const status = await axios
      .post(process.env.REACT_APP_API_URL + "/approval-hours", {
        username: username,
        month: monthNumber,
        year: year,
      })
      .then((response) => {
        if (response.data?.status) {
          setApprovalStatus(response.data?.status);
        } else {
          setApprovalStatus(false);
        }
      });
  };

  return (
    <div
      className={`${
        modalHidden
          ? "opacity-0"
          : "form-wrapper h-screen w-full fixed left-0 top-0 flex justify-center mt-[67px] md:mt-0 z-[99]"
      }`}
    >
      <form className="bg-white rounded-lg shadow-2xl w-2/4">
        <header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
          <div className="flex">
            <div className="material-icons-outline mr-4">
              <CalendarMonthOutlinedIcon />
            </div>
            <p className="text-left">{daySelected.format("dddd, MMMM DD")}</p>
          </div>

          <div>
            {selectedEvent && (
              <span
                onClick={() => {
                  handleOpenDeleteEntireModal();
                }}
                className="material-icons-outlined text-gray-400 cursor-pointer"
              >
                <DeleteForeverOutlinedIcon className="mr-3" />
              </span>
            )}
            <button
              onClick={() => {
                setShowEventModal(false);
                document.body.style.overflow = "unset";
              }}
            >
              <span className="material-icons-outlined text-gray-400">
                <CloseOutlinedIcon />
              </span>
            </button>
          </div>
        </header>
        <div className="px-4 py-6">
          <div>
            {selectedEvent && selectedEvent.startTime && (
              <div>
                <div className="text-left mb-4 flex flex-row items-center">
                  <div className="flex items-center mr-4">
                    <AccessTimeIcon />{" "}
                  </div>
                  <div>
                    {selectedEvent.startTime && (
                      <p className="text-sm">
                        <span className="text-sm text-green-600">Prihod: </span>
                        {dayjs(selectedEvent.startTime).utc().format("HH:mm")}
                      </p>
                    )}

                    {selectedEvent.lunchTime && (
                      <p className="text-sm">
                        <span className="text-sm text-yellow-500">
                          Malica:{" "}
                        </span>
                        {dayjs(selectedEvent.lunchTime).utc().format("HH:mm")} -{" "}
                        {dayjs(selectedEvent.lunchTimeEnd)
                          .utc()
                          .format("HH:mm")}
                      </p>
                    )}

                    {selectedEvent.endTime && (
                      <p className="text-sm">
                        <span className="text-sm text-red-500">Odhod: </span>
                        {dayjs(selectedEvent.endTime).utc().format("HH:mm")}
                      </p>
                    )}
                  </div>
                  <div className="flex items-center ml-1">
                    {/* {!approvalStatus && ( */}
                    <span className="ml-1 cursor-pointer">
                      <EditOutlinedIcon
                        onClick={() => {
                          handleOpenModal("startTime");
                        }}
                      />
                      <DeleteForeverOutlinedIcon
                        className="mr-3"
                        onClick={() => {
                          setHasPaId(false);
                          handleOpenDeleteModal();
                        }}
                      />
                    </span>
                    {/* )} */}
                  </div>
                </div>
              </div>
            )}

            {selectedEvent ? (
              <div>
                <div className="text-left mb-4">
                  <div className="flex flex-row items-center">
                    <div>
                      {rdc !== 0 && rdc != null ? (
                        <p className="text-sm text-left">Delovne ure: {rdc}</p>
                      ) : (
                        ""
                      )}
                      {rdv !== 0 && rdv != null ? (
                        <p className="text-sm  text-left">Vožnja: {rdv}</p>
                      ) : (
                        ""
                      )}

                      {nadure !== 0 && nadure != null ? (
                        <p className="text-sm text-left">Nadure: {nadure}</p>
                      ) : (
                        ""
                      )}

                      {selectedEvent && selectedEvent.koriscenje ? (
                        <div className="flex items-center">
                          <p className="text-sm text-left">
                            Koriščenje ur: {selectedEvent.koriscenje}
                          </p>
                          <DeleteForeverOutlinedIcon
                            className="ml-3 cursor-pointer"
                            onClick={handleOvertimeDeleteModalToggle}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    {(rdc !== 0 && rdc !== null) ||
                    (rdv !== 0 && rdv !== null) ||
                    (nadure !== 0 && nadure !== null) ? (
                      <span>
                        <DeleteForeverOutlinedIcon
                          className="ml-3 cursor-pointer"
                          onClick={() => {
                            setHasPaId(true);
                            handleOpenDeleteModal();
                          }}
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  {selectedEvent && selectedEvent.remote && (
                    <div className="flex items-center">
                      <BroadcastOnPersonalOutlined className="mr-4" />
                      <p className="text-left">Delo od doma</p>
                      <DeleteForeverOutlinedIcon
                        className="ml-3 cursor-pointer"
                        onClick={deleteRemote}
                      />
                    </div>
                  )}

                  <div className="ml-10">
                    {dop != null ? (
                      <p className="text-sm  text-left text-red-500">Dopust</p>
                    ) : (
                      ""
                    )}

                    {bp8 && (
                      <p className="text-sm  text-left text-red-500">
                        Bolniški stalež
                      </p>
                    )}

                    {drp && (
                      <p className="text-sm  text-left text-red-500">DRP</p>
                    )}
                  </div>
                  {selectedEvent && selectedEvent.dop && (
                    <div>
                      <p className="text-red-600 text-left">
                        <AccessibilityNewIcon className="mr-3" /> Dopust
                      </p>
                    </div>
                  )}

                  {selectedEvent && selectedEvent.bp8 && (
                    <div>
                      <p className="text-red-600 text-left">
                        <SickIcon className="mr-3" /> Bolniški stalež
                      </p>
                    </div>
                  )}

                  {selectedEvent && opomba && (
                    <div className="text-left mt-3 bg-gray-50 p-3">
                      <small className="text-xs">
                        <strong>Opombe</strong>
                      </small>
                      <p className="text-sm mt-2">{opomba}</p>
                      <div className="mt-3">
                        <Button
                          className="classic-small-btn danger-btn"
                          onClick={deleteNotes}
                        >
                          <small className="text-white">Izbriši</small>
                        </Button>
                      </div>
                    </div>
                  )}

                  {(selectedEvent &&
                    selectedEvent.koriscenje < 8 &&
                    !selectedEvent.startTime) ||
                  (selectedEvent.bp8 < 8 && !selectedEvent.startTime) ||
                  !selectedEvent.startTime ? (
                    <div className="mb-5 mt-10">
                      <p className="text-left text-gray-400 mb-4">
                        Dodaj redno delo
                      </p>
                      <div>
                        <WorkingTimeForm
                          daySelected={daySelected}
                          username={username}
                          selectedEvent={selectedEvent}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {selectedEvent &&
                    !selectedEvent.notes &&
                    !hiddenNotesForm && (
                      <div className="mt-3">
                        <NotesForm
                          daySelected={daySelected}
                          username={username}
                          selectedEvent={selectedEvent}
                          triggerRefresh={setHiddenNotes}
                        />
                      </div>
                    )}
                </div>
              </div>
            ) : (
              ""
            )}

            {!selectedEvent && (
              <div className="mb-5 mt-1">
                <p className="text-left text-gray-400 mb-4">Redno delo</p>
                <div>
                  <WorkingTimeForm
                    daySelected={daySelected}
                    username={username}
                    selectedEvent={selectedEvent}
                  />
                </div>
              </div>
            )}

            <div>
              {!selectedEvent &&
                user.idTokenClaims.roles?.includes("Admin") && (
                  <div>
                    <p className="text-left text-gray-400 mb-4">
                      Ostale postavke
                    </p>
                    <div className="mb-2">
                      <Button
                        className="btn w-full secondary-btn btn-gray"
                        variant="contained"
                        onClick={addHoliday}
                      >
                        Dopust
                      </Button>
                    </div>
                  </div>
                )}

              {!selectedEvent &&
                user.idTokenClaims.roles?.includes("Admin") && (
                  <div className="mb-2">
                    <Button
                      className="btn w-full secondary-btn btn-gray"
                      variant="contained"
                      onClick={toggleSickday}
                    >
                      Bolniški stalež
                    </Button>

                    <div
                      className={`${sickdayToggle ? "block" : "hidden"} mt-2`}
                    >
                      <TextField
                        error={sickdayInputError}
                        required
                        type="number"
                        className="w-full"
                        placeholder="Vpiši število ur..."
                        defaultValue="8"
                        inputRef={sickdayInputRef}
                        onChange={checkSickdayInput}
                      />
                      <div className={sickdayInputError ? "block" : "hidden"}>
                        <p className="text-red-500 text-center">
                          Neveljaven vnos.
                        </p>
                      </div>
                      <div className="mt-2">
                        <Button
                          className="btn w-full primary-btn btn-green"
                          variant="contained"
                          onClick={addSickday}
                        >
                          POTRDI
                        </Button>
                      </div>
                    </div>
                  </div>
                )}

              {selectedEvent &&
                !selectedEvent.remote &&
                mongoUser &&
                mongoUser.allowedRemote && (
                  <div className="mb-2 w-fit">
                    <Button
                      size="small"
                      className="btn secondary-btn btn-gray "
                      variant="contained"
                      onClick={addRemote}
                    >
                      Delo od doma
                    </Button>
                  </div>
                )}

              {!selectedEvent && mongoUser && mongoUser.allowedRemote && (
                <div className="mb-2">
                  <Button
                    className="btn w-full secondary-btn btn-gray"
                    variant="contained"
                    onClick={addRemote}
                  >
                    Delo od doma
                  </Button>
                </div>
              )}

              {!selectedEvent && (
                <div className="mb-2">
                  <Button
                    className="btn w-full secondary-btn btn-gray"
                    variant="contained"
                    onClick={addBusinessTrip}
                  >
                    Službeno potovanje
                  </Button>
                </div>
              )}
              {!selectedEvent && (
                <div>
                  <div>
                    <Button
                      className="btn w-full secondary-btn btn-gray"
                      variant="contained"
                      onClick={handleKoriscenjeToggle}
                    >
                      Koriščenje nadur
                    </Button>
                  </div>
                  <div
                    className={`${koriscenjeToggle ? "block" : "hidden"} mt-2`}
                  >
                    <TextField
                      error={overtimeUseInputError}
                      required
                      type="number"
                      className="w-full"
                      placeholder="Vpiši število ur..."
                      inputRef={overtimeUseInputRef}
                      onChange={checkOvertimeUseInput}
                    />
                    <div className={overtimeUseInputError ? "block" : "hidden"}>
                      <p className="text-red-500 text-center">
                        Neveljaven vnos.
                      </p>
                    </div>
                    <div className="mt-2">
                      <Button
                        className="btn w-full primary-btn btn-green"
                        variant="contained"
                        onClick={overtimeUse}
                      >
                        POTRDI
                      </Button>
                    </div>
                  </div>
                </div>
              )}

              {selectedEvent && diffStatus && !selectedEvent.koriscenje && (
                <div>
                  <div>
                    <Button
                      className="btn w-full secondary-btn btn-gray"
                      variant="contained"
                      onClick={handleKoriscenjeToggle}
                    >
                      Koriščenje nadur
                    </Button>
                  </div>
                  <div
                    className={`${koriscenjeToggle ? "block" : "hidden"} mt-2`}
                  >
                    <TextField
                      error={overtimeUseInputError}
                      required
                      type="number"
                      className="w-full"
                      placeholder="Vpiši število ur..."
                      inputRef={overtimeUseInputRef}
                      onChange={checkOvertimeUseInput}
                    />
                    <div className={overtimeUseInputError ? "block" : "hidden"}>
                      <p className="text-red-500 text-center">
                        Neveljaven vnos.
                      </p>
                    </div>
                    <div className="mt-2">
                      <Button
                        className="btn w-full primary-btn btn-green"
                        variant="contained"
                        onClick={overtimeUse}
                      >
                        POTRDI
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-5">
                <p className="text-left">
                  Stanje letnega dopusta:{" "}
                  <span>
                    <span className="font-bold">{vacationDays.dop}</span>
                    {vacationDays.lastYear != 0 && (
                      <span> ({vacationDays.lastYear})</span>
                    )}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="flex justify-center items-center"
      >
        <div
          className="modal-container flex flex-col gap-5 bg-slate-50 p-3 lg:w-1/6 md:w-1/5 sm:w-1/4"
          style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
        >
          <div className="modal-header flex justify-between">
            <h2 id="modal-modal-title">Urejanje</h2>
            <button onClick={handleCloseModal}>
              <CloseOutlinedIcon />
            </button>
          </div>
          <div className="modal-body">
            <div className="flex flex-col items-center">
              <div className="mb-4 w-full">
                <TextField
                  id="time"
                  label="Čas Prihoda"
                  type="time"
                  className="w-full"
                  // defaultValue={dayjs(selectedEvent.startTime)
                  //   .utc()
                  //   .format("HH:mm")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={
                    selectedEvent
                      ? dayjs(selectedEvent.startTime).utc().format("HH:mm")
                      : "00:00" // Default value if startTime is missing
                  }
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  onChange={(e) => {
                    setDisplayStartTime(e.target.value);
                  }}
                />
              </div>
              <div className="mb-4 w-full">
                <TextField
                  id="time"
                  label="Čas Odhoda"
                  type="time"
                  className="w-full"
                  // defaultValue={dayjs(selectedEvent.endTime)
                  //   .utc()
                  //   .format("HH:mm")}
                  defaultValue={
                    selectedEvent
                      ? dayjs(selectedEvent.endTime).utc().format("HH:mm")
                      : "00:00" // Default value if startTime is missing
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  onChange={(e) => {
                    setDisplayEndTime(e.target.value);
                  }}
                />
              </div>
              <div className="w-full">
                <Button
                  className="btn w-full primary-btn btn-green"
                  variant="contained"
                  onClick={() => {
                    setModalOpen(false);

                    const datePart = dayjs(selectedEvent.date).format(
                      "YYYY-MM-DD"
                    );

                    // Declare local variables to store formatted time
                    let updatedStartTime = displayStartTime;
                    let updatedEndTime = displayEndTime;

                    // Check and update start time if needed
                    if (displayStartTime.length > 5) {
                      updatedStartTime = dayjs
                        .utc(displayStartTime)
                        .format("HH:mm");
                      setDisplayStartTime(updatedStartTime);
                    }

                    // Check and update end time if needed
                    if (displayEndTime.length > 5) {
                      updatedEndTime = dayjs
                        .utc(displayEndTime)
                        .format("HH:mm");
                      setDisplayEndTime(updatedEndTime);
                    }

                    // Use the local variables with the updated time
                    const [startHours, startMinutes] =
                      updatedStartTime.split(":");
                    const [endHours, endMinutes] = updatedEndTime.split(":");

                    const parsedStartTime = new Date(
                      `${datePart}T${startHours}:${startMinutes}:00Z`
                    );

                    const parsedEndTime = new Date(
                      `${datePart}T${endHours}:${endMinutes}:00Z`
                    );

                    // Dispatch the updated times
                    dispatch(
                      updateEntry({
                        updateType: "time",
                        entry_id: selectedEvent.entry_id,
                        date: selectedEvent.date,
                        username: username,
                        startTime: parsedStartTime,
                        endTime: parsedEndTime,
                      })
                    );

                    selectedEvent.startTime = parsedStartTime;
                    selectedEvent.endTime = parsedEndTime;
                  }}
                >
                  Shrani
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="flex justify-center items-center"
      >
        <div
          className="modal-container flex flex-col gap-5 bg-slate-50 p-3 lg:w-1/5 md:w-1/4"
          style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
        >
          <div className="modal-header flex justify-between">
            <h2 id="modal-modal-title">Brisanje vnosa</h2>
            <button onClick={handleCloseDeleteModal}>
              <CloseOutlinedIcon />
            </button>
          </div>
          <div className="modal-body">
            <div className="flex flex-col items-center">
              <p>Ali ste prepričani, da želite izbrisati ta vnos?</p>
              <div className="w-full flex justify-between mt-2">
                <Button
                  size="small"
                  sx={{
                    color: "#999",
                    borderColor: "#999",
                  }}
                  className=""
                  variant="outlined"
                  onClick={handleCloseDeleteModal}
                >
                  Prekliči
                </Button>
                <Button
                  size="small"
                  className=""
                  color="error"
                  variant="contained"
                  onClick={() => {
                    handleDeleteEntry();
                    handleCloseDeleteModal();
                    setShowEventModal(false);
                    document.body.style.overflow = "unset";
                  }}
                >
                  Izbriši
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={deleteEntireModalOpen}
        onClose={handleCloseDeleteEntireModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="flex justify-center items-center"
      >
        <div
          className="modal-container flex flex-col gap-5 bg-slate-50 p-3 lg:w-1/5 md:w-1/4"
          style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
        >
          <div className="modal-header flex justify-between">
            <h2 id="modal-modal-title">Brisanje vnosa</h2>
            <button onClick={handleCloseDeleteEntireModal}>
              <CloseOutlinedIcon />
            </button>
          </div>
          <div className="modal-body">
            <div className="flex flex-col items-center">
              <p>Ali ste prepričani, da želite izbrisati ta vnos?</p>
              <div className="w-full flex justify-between mt-2">
                <Button
                  size="small"
                  sx={{
                    color: "#999",
                    borderColor: "#999",
                  }}
                  className=""
                  variant="outlined"
                  onClick={handleCloseDeleteEntireModal}
                >
                  Prekliči
                </Button>
                <Button
                  size="small"
                  className=""
                  color="error"
                  variant="contained"
                  onClick={() => {
                    handleDeleteEntireEntry();
                    handleCloseDeleteEntireModal();
                    setShowEventModal(false);
                    document.body.style.overflow = "unset";
                  }}
                >
                  Izbriši
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={overtimeDeleteModalOpen}
        onClose={handleOvertimeDeleteModalToggle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="flex justify-center items-center"
      >
        <div
          className="modal-container flex flex-col gap-5 bg-slate-50 p-3 lg:w-1/5 md:w-1/4"
          style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
        >
          <div className="modal-header flex justify-between">
            <h2 id="modal-modal-title">Brisanje vnosa</h2>
            <button onClick={handleOvertimeDeleteModalToggle}>
              <CloseOutlinedIcon />
            </button>
          </div>
          <div className="modal-body">
            <div className="flex flex-col items-center">
              <p>Ali ste prepričani, da želite izbrisati ta vnos?</p>
              <div className="w-full flex justify-between mt-2">
                <Button
                  size="small"
                  sx={{
                    color: "#999",
                    borderColor: "#999",
                  }}
                  className=""
                  variant="outlined"
                  onClick={handleOvertimeDeleteModalToggle}
                >
                  Prekliči
                </Button>
                <Button
                  size="small"
                  className=""
                  color="error"
                  variant="contained"
                  onClick={() => {
                    handleDeleteOvertimeUse();
                    handleOvertimeDeleteModalToggle();
                    setShowEventModal(false);
                    document.body.style.overflow = "unset";
                  }}
                >
                  Izbriši
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
