import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Calendar.css";
import CalendarHeader from "./CalendarHeader";
import Month from "./Month";
import Sidebar from "./Sidebar";
import { getMonth } from "../../util";
import GlobalContext from "../../context/GlobalContext";
import EventModal from "./EventModal";
import dayjs from "dayjs";

const Calendar = ({ admin, approvalStatus }) => {
  const { monthIndex, showEventModal } = useContext(GlobalContext);
  const [currenMonth, setCurrentMonth] = useState(getMonth(monthIndex));
  const location = useLocation();

  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);

  useEffect(() => {
    if (
      localStorage.getItem("currentMonth") === null ||
      localStorage.getItem("currentYear") === null
    ) {
      localStorage.removeItem("currentMonth");
      localStorage.removeItem("currentYear");

      localStorage.setItem("currentMonth", dayjs().month());
      localStorage.setItem("currentYear", dayjs().year());
    }

    if (localStorage.getItem("currentYear") === "1900") {
      localStorage.removeItem("currentYear");
      localStorage.setItem("currentYear", dayjs().year());
    }

    const storedMonth = localStorage.getItem("currentMonth");
    if (storedMonth) {
      setCurrentMonth(getMonth(parseInt(storedMonth)));
    }
  }, []);

  return (
    <div>
      <div className="calendar">
        {showEventModal && <EventModal />}

        {!location.pathname.includes("/user") && (
          <h1 className="pt-3 pb-2 md:hidden text-sm text-left text-gray-500 font-light">
            Evidenca opravljenih ur
          </h1>
        )}

        <CalendarHeader />

        <div className="calendar-wrapper flex flex-row">
          {!location.pathname.includes("/user") && (
            <div>
              <Sidebar />
            </div>
          )}

          <div className="calendar-content flex flex-1 flex-col mb-5 md:mb-0">
            <Month
              month={currenMonth}
              admin={admin}
              approvalStatus={approvalStatus}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Calendar;
